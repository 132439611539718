import { ref } from 'vue';
import axios from 'axios';
import { loadRecaptcha } from '@/js/recaptcha';
import { useRecaptcha } from '@/js/quests/dashboard/composables/useRecaptcha';
import { validateEmail } from '@/js/helpers/emailValidations';
import { useSubscribeModalStore } from '@/js/store/subscribe/SubscribeModalStore';
import { getTrans } from '@/js/helpers/getTrans';
import { PromoCode } from '../types/types';
import { subscriberSourceEnum } from '@/js/enums/subscriberSourceEnum';

export const useSubscribeForm = (subscriberSource: subscriberSourceEnum, buttonId: string, isNewsletter = false) => {
    const subscribeModalStore = useSubscribeModalStore();

    const email = ref('');
    const errorMessage = ref('');
    const promoCodeObject = ref<PromoCode | null>(null);

    const handleSubscribeSuccess = () => {
        errorMessage.value = '';
        email.value = '';

        subscribeModalStore.setSubscribeModal(false);
        subscribeModalStore.setSubscribeSuccessModal(true, isNewsletter);

        if (isNewsletter) {
            subscribeModalStore.isNewsletter = true;
        }
    };

    const onSubscribeSubmit = async (recaptchaResponse: string) => {
        try {
            const response = await axios.post('/cryptocurrency-prices/subscribe', {
                email: email.value,
                source: subscriberSource,
                'g-recaptcha-response': recaptchaResponse,
            });

            if (response.data?.promo_code) {
                promoCodeObject.value = response.data.promo_code;
            }

            handleSubscribeSuccess();
        } catch (error) {
            if (error.response?.data?.message) {
                errorMessage.value = error.response.data.message;
            }
        }
    };

    const { executeRecaptcha, initRecaptcha } = useRecaptcha({
        proceedFunction: onSubscribeSubmit,
    }, buttonId);

    const proceed = async () => {
        try {
            if (!email.value) {
                errorMessage.value = getTrans(
                    'validation.emailAddressRequired',
                    'Email address is required',
                );

                return;
            }

            if (!validateEmail(email.value)) {
                errorMessage.value = getTrans(
                    'validation.enterValidEmail',
                    'Enter a valid email address',
                );

                return;
            }

            errorMessage.value = '';

            await executeRecaptcha();
        } catch (error) {
            errorMessage.value = error;
        }
    };

    let reCAPTCHAInitialized = false;

    const loadRecaptchaAndProceed = async () => {
        try {
            if (!reCAPTCHAInitialized) {
                await loadRecaptcha();
                await initRecaptcha();
                reCAPTCHAInitialized = true;
            }
            await proceed();
        } catch (error) {
            errorMessage.value = error;
        }
    };

    return {
        email,
        errorMessage,
        promoCodeObject,
        loadRecaptchaAndProceed,
    };
};
